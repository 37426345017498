import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main>
     Hi
    </main>
  )
}

export default NotFoundPage
